import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Autocomplete,
  Button,
  Form,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Formik,
  Grid,
  icons,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useFormikContext,
  Yup,
} from "syklone/ui/index.js";
import { WidgetInputNumber } from "syklone/components/widgets/index.js";

// imports: local
import { ImageNodeRandomisation, ImageRoughening } from "./data/index.js";
import { WidgetHelperCard } from "../../../widgets/index.js";
import { withInfoPopover } from "../../../hoc/index.js";
import WidgetJsonPreview from "./widget_json_preview.jsx";
import WidgetSection from "./widget_section.jsx";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  family: Yup.string().required("Family is required"),
  scanningParameterId: Yup.object().required("Scanning parameter is required"),
  sliceHeight: Yup.number().positive("Slice height must be a positive number.").required("Slice height is required."),
  unitCell: Yup.string().required("Unit cell is required."),
  nodeRandomisation: Yup.object().shape({
    randomSeed: Yup.number()
      .integer("Random seed must be an integer.")
      .min(0, "Field must be zero or positive.")
      .required("Random seed is required."),
    minX: Yup.number()
      .max(Yup.ref("maxX"), "Minimum value must be less than or equal to minimum")
      .positive("Field must have positive value.")
      .required("Minimum X axis is required."),
    maxX: Yup.number()
      .min(Yup.ref("minX"), "Maximum value must be greater than or equal to minimum")
      .positive("Field must have positive value.")
      .required("Maximum X axis is required."),
    minY: Yup.number()
      .max(Yup.ref("maxY"), "Minimum value must be less than or equal to minimum")
      .positive("Field must have positive value.")
      .required("Minimum Y axis is required."),
    maxY: Yup.number()
      .min(Yup.ref("minY"), "Maximum value must be greater than or equal to minimum")
      .positive("Field must have positive value.")
      .required("Maximum Y axis is required."),
    minZ: Yup.number()
      .max(Yup.ref("maxZ"), "Minimum value must be less than or equal to minimum")
      .positive("Field must have positive value.")
      .required("Minimum Z axis is required."),
    maxZ: Yup.number()
      .min(Yup.ref("minZ"), "Maximum value must be greater than or equal to minimum")
      .positive("Field must have positive value.")
      .required("Maximum Z axis is required."),
  }),
  scaffoldRotation: Yup.object().shape({
    x: Yup.number()
      .min(-180, "Minimal value for the angle is -180 degrees.")
      .max(180, "Maximal value for the angle is -180 degrees.")
      .required("Scaffold rotation angle is required."),
    y: Yup.number()
      .min(-180, "Minimal value for the angle is -180 degrees.")
      .max(180, "Maximal value for the angle is -180 degrees.")
      .required("Scaffold rotation angle is required."),
    z: Yup.number()
      .min(-180, "Minimal value for the angle is -180 degrees.")
      .max(180, "Maximal value for the angle is -180 degrees.")
      .required("Scaffold rotation angle is required."),
  }),
  roughening: Yup.object().shape({
    minExtension: Yup.number()
      .max(Yup.ref("maxExtension"), "Minimum extension must be less than or equal to maximum extension")
      .required("Minimum extension is required."),
    maxExtension: Yup.number()
      .min(Yup.ref("minExtension"), "Maximum extension must be more than or equal to minimum extension")
      .required("Maximum extension is required."),
    randomSeed: Yup.number()
      .integer("Random seed must be an integer.")
      .min(0, "Field must be zero or positive.")
      .required("Random seed is required."),
    distribution: Yup.array()
      .min(1, "Minimal number of breakpoints is 1.")
      .of(Yup.number().min(0, "All distribution breakpoints must be greater than or equal 0.").required()),
  }),
});

const WidgetHelperCardWithInfoPopover = withInfoPopover(WidgetHelperCard);

const SliceHeightSection = ({ setFieldTouched, setFieldValue, handleBlur, sliceHeight, error, touched }) => {
  const [sliceHeightUnit, setSliceHeightUnit] = useState("mm");

  return (
    <WidgetSection title="Slice Height">
      <Grid container item spacing={1} alignItems="center">
        <Grid item sx={{ width: "200px" }}>
          <WidgetInputNumber
            name="sliceHeight"
            label="Slice Height"
            suffix={sliceHeightUnit}
            value={sliceHeightUnit === "μm" ? sliceHeight * 1000 : sliceHeight}
            onApply={(key, value) => {
              setFieldTouched(key, true);
              let output = value;
              if (sliceHeightUnit === "μm") output = output / 1000;
              setFieldValue(key, output);
            }}
            onBlur={handleBlur}
            error={Boolean(error) && touched}
            helperText={touched && error}
          />
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={sliceHeightUnit}
            exclusive
            onChange={(e) => {
              setSliceHeightUnit(e.target.value);
            }}
            aria-label="text alignment"
          >
            <ToggleButton sx={{ textTransform: "none" }} value="mm" aria-label="milimeter">
              mm
            </ToggleButton>
            <ToggleButton sx={{ textTransform: "none" }} value="μm" aria-label="micrometer">
              μm
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </WidgetSection>
  );
};

SliceHeightSection.propTypes = {
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  sliceHeight: PropTypes.number,
  error: PropTypes.bool,
  touched: PropTypes.bool,
};

const UnitCellsSection = ({ unitCells, onUnitCellUpload }) => {
  const { values, errors, touched, handleChange } = useFormikContext();
  const [file, setFile] = useState(null);

  const onInputChange = (event) => {
    const file = event.target.files[0];
    if (file === undefined) return;
    setFile(file);
  };

  const handleUploadUnitCell = () => onUnitCellUpload(file);

  return (
    <WidgetSection
      title="Unit cells"
      description="Generation of porous structures involves the repetition of unit cells across a scaffold. Porous structures shall be generated in centred parts, so that randomisation is accurate. This file is used to define the structure of the base unit cell."
    >
      <Grid container item xs={12} flexDirection="column" spacing={2}>
        <Grid item>
          <FormControl
            variant="outlined"
            sx={{ minWidth: "220px!important" }}
            error={touched.unitCell && Boolean(errors.unitCell)}
          >
            <InputLabel id="select-unit-cell" key="unitCellSelect">
              Select Unit Cell
            </InputLabel>
            <Select name="unitCell" label="Select Unit Cell" onChange={handleChange} value={values.unitCell}>
              {unitCells.map((unitCell, index) => {
                return (
                  <MenuItem key={unitCell} value={unitCell}>
                    {unitCell}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.unitCell && touched.unitCell && <FormHelperText>{errors.unitCell}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item>
          <label htmlFor="uploadUnitCell">
            <input
              type="file"
              accept=".txt"
              id="uploadUnitCell"
              name="uploadUnitCell"
              multiple={false}
              onChange={onInputChange}
              style={{ display: "none" }}
            />
            <Button
              aria-label="uploadUnitCell"
              component="span"
              startIcon={<icons.mui.AttachFile />}
              variant="outlined"
              sx={(theme) => {
                return {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.main + "11",
                  "&:hover": { backgroundColor: theme.palette.primary.main + "22" },
                };
              }}
            >
              {file ? file.name : "Select unit cell file"}
            </Button>
          </label>
          <Button
            onClick={handleUploadUnitCell}
            startIcon={<icons.mui.Upload />}
            disabled={!file}
            variant="outlined"
            sx={{ marginLeft: "1rem" }}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
    </WidgetSection>
  );
};

UnitCellsSection.propTypes = {
  unitCells: PropTypes.array,
  onUnitCellUpload: PropTypes.func,
};

const RougheningSection = () => {
  const { values, errors, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();

  const [distribution, setDistribution] = useState(values.roughening.distribution);
  const [rougheningImageData, setRougheningImageData] = useState();

  const handleDistributionApply = (e) => {
    const lst = e.target.value
      .split(",")
      .map((e) => {
        if (e !== "") return Number(e);
      })
      .filter((e) => !isNaN(e));

    setDistribution(lst.join(", "));
    handleBlur(e);
    setFieldTouched("roughening.distribution", true);
    setFieldValue("roughening.distribution", lst);
  };

  useEffect(() => {
    const fetchImage = async () => {
      const blob = await fetch(ImageRoughening).then((r) => r.blob());
      const imgFile = new File([blob], "Node randomisation", { type: "image/png" });
      setRougheningImageData(imgFile);
    };

    fetchImage();
  }, []);

  return (
    <WidgetSection
      title="Roughening"
      description="Roughening consists on the extension or reduction of a strut's length by an absolute value. The extensions are applied at random using a probability distribution. If a strut is shortened by a value greater than its length, it is deleted."
    >
      <Grid item container spacing={2} flexDirection="row">
        <Grid item>
          <WidgetInputNumber
            name="minExtension"
            label="Minimum Extension"
            suffix="mm"
            value={values.roughening.minExtension}
            onApply={(key, value) => {
              setFieldTouched("roughening.minExtension", true);
              setFieldValue("roughening.minExtension", value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.roughening?.minExtension) && touched.roughening?.minExtension}
            helperText={touched.roughening?.minExtension && errors.roughening?.minExtension}
          />
        </Grid>
        <Grid item>
          <WidgetInputNumber
            name="maxExtension"
            label="Maximum Extension"
            suffix="mm"
            value={values.roughening.maxExtension}
            onApply={(key, value) => {
              setFieldTouched("roughening.maxExtension", true);
              setFieldValue("roughening.maxExtension", value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.roughening?.maxExtension) && touched.roughening?.maxExtension}
            helperText={touched.roughening?.maxExtension && errors.roughening?.maxExtension}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} alignItems="center">
        <Grid item>
          <TextField
            name="distribution"
            label="Probability distribution"
            variant="outlined"
            value={distribution}
            onChange={(e) => setDistribution(e.target.value)}
            onBlur={handleDistributionApply}
            error={Boolean(errors.roughening?.distribution) && touched.roughening?.distribution}
            helperText={touched.roughening?.distribution && errors.roughening?.distribution}
          />
        </Grid>
        <Grid item>
          <WidgetHelperCardWithInfoPopover title="Probability distribution" imageData={rougheningImageData}>
            <Grid item>
              Range of probabilities expressed as a list of positive integers. The length of the list defines the amount
              of breakpoints between the minimum and maximum values: 1 value will determine a constant distribution of
              probability for all struts. 2 values will determine 2 ranges of probabilities, etc. The magnitude of the
              value will determine the probability value for that range. ie: with min -0.3, max 0.3, a distribution of
              (2, 1) will reflect twice the probability from min value to 0, than from 0 to max value.
            </Grid>
          </WidgetHelperCardWithInfoPopover>
        </Grid>
      </Grid>
      <Grid container item spacing={2} alignItems="center">
        <Grid item>
          <WidgetInputNumber
            name="randomSeed"
            label="Random Seed"
            value={values.roughening.randomSeed}
            onApply={(key, value) => {
              setFieldTouched("roughening.randomSeed", true);
              setFieldValue("roughening.randomSeed", value);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.roughening?.randomSeed) && touched.roughening?.randomSeed}
            helperText={touched.roughening?.randomSeed && errors.roughening?.randomSeed}
          />
        </Grid>
        <Grid item>
          <WidgetHelperCardWithInfoPopover title="Random Seed">
            <Grid item>
              Set a random seed for the random function. Setting a random seed produces deterministic random results.
            </Grid>
          </WidgetHelperCardWithInfoPopover>
        </Grid>
      </Grid>
    </WidgetSection>
  );
};

const NodeRandomisationSection = () => {
  const [isNodeRandomisationGlobal, setIsNodeRandomisationGlobal] = useState(false);
  const { values, errors, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();

  const [axesValuesImageData, setAxesValuesImageData] = useState();

  useEffect(() => {
    const fetchImage = async () => {
      const blob = await fetch(ImageNodeRandomisation).then((r) => r.blob());
      const imgFile = new File([blob], "Node randomisation", { type: "image/png" });
      setAxesValuesImageData(imgFile);
    };

    fetchImage();
  }, []);

  useEffect(() => {
    if (!isNodeRandomisationGlobal) return;
    const { nodeRandomisation } = values;
    setFieldValue("nodeRandomisation.minY", nodeRandomisation.minX);
    setFieldValue("nodeRandomisation.maxY", nodeRandomisation.maxX);
    setFieldValue("nodeRandomisation.minZ", nodeRandomisation.minX);
    setFieldValue("nodeRandomisation.maxZ", nodeRandomisation.maxX);
  }, [isNodeRandomisationGlobal, values.nodeRandomisation.minX, values.nodeRandomisation.maxX]);

  const calcAxisValuePercentage = (value) => Math.floor(value * 100 * 1000) / 1000;

  return (
    <WidgetSection
      title="Node randomisation"
      description="This set defines a translation by a random vector, applied to each node of the cell scaffold. The value reflects the proportion by which the node is translated in XYZ."
    >
      <Grid container item flexDirection="column" spacing={2}>
        <Grid container item spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h6" color="inherit" noWrap>
              Per axis values
            </Typography>
          </Grid>
          <Grid item>
            <WidgetHelperCardWithInfoPopover title="Per axis values" imageData={axesValuesImageData} width={450}>
              <Grid item>
                {`Input minimum and maximum percentage of randomisation for each axis. The node will be translated in this
                axis a random amount between the minimum and maximum values defined here, and it may happen in either a
                positive or negative direction (also chosen at random). The percentage is in reference to unit cell size
                for this axis. Check "Set for all axes" switch to apply X axis values for the other ones.`}
              </Grid>
            </WidgetHelperCardWithInfoPopover>
          </Grid>
        </Grid>
        <Grid container item spacing={2} alignItems="center">
          <Grid item>
            <WidgetInputNumber
              name="minX"
              label="Minimum X axis"
              suffix="%"
              value={calcAxisValuePercentage(values.nodeRandomisation.minX)}
              onApply={(key, value) => {
                setFieldTouched("nodeRandomisation.minX", true);
                setFieldValue("nodeRandomisation.minX", value / 100);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.nodeRandomisation?.minX) && touched.nodeRandomisation?.minX}
              helperText={touched.nodeRandomisation?.minX && errors.nodeRandomisation?.minX}
            />
          </Grid>
          <Grid item>
            <WidgetInputNumber
              name="maxX"
              label="Maximum X axis"
              suffix="%"
              value={calcAxisValuePercentage(values.nodeRandomisation.maxX)}
              onApply={(key, value) => {
                setFieldTouched("nodeRandomisation.maxX", true);
                setFieldValue("nodeRandomisation.maxX", value / 100);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.nodeRandomisation?.maxX) && touched.nodeRandomisation?.maxX}
              helperText={touched.nodeRandomisation?.maxX && errors.nodeRandomisation?.maxX}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              label="Set for all axes"
              control={
                <Switch
                  checked={isNodeRandomisationGlobal}
                  onChange={() => setIsNodeRandomisationGlobal(!isNodeRandomisationGlobal)}
                  name="isNodeRandomisationGlobal"
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <WidgetInputNumber
              name="minY"
              label="Minimum Y axis"
              suffix="%"
              value={calcAxisValuePercentage(values.nodeRandomisation.minY)}
              disabled={isNodeRandomisationGlobal}
              onApply={(key, value) => {
                setFieldTouched("nodeRandomisation.minY", true);
                setFieldValue("nodeRandomisation.minY", value / 100);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.nodeRandomisation?.minY) && touched.nodeRandomisation?.minY}
              helperText={touched.nodeRandomisation?.minY && errors.nodeRandomisation?.minY}
            />
          </Grid>
          <Grid item>
            <WidgetInputNumber
              name="maxY"
              label="Maximum Y axis"
              suffix="%"
              value={calcAxisValuePercentage(values.nodeRandomisation.maxY)}
              disabled={isNodeRandomisationGlobal}
              onApply={(key, value) => {
                setFieldTouched("nodeRandomisation.maxY", true);
                setFieldValue("nodeRandomisation.maxY", value / 100);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.nodeRandomisation?.maxY) && touched.nodeRandomisation?.maxY}
              helperText={touched.nodeRandomisation?.maxY && errors.nodeRandomisation?.maxY}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <WidgetInputNumber
              name="minZ"
              label="Minimum Z axis"
              suffix="%"
              value={calcAxisValuePercentage(values.nodeRandomisation.minZ)}
              disabled={isNodeRandomisationGlobal}
              onApply={(key, value) => {
                setFieldTouched("nodeRandomisation.minZ", true);
                setFieldValue("nodeRandomisation.minZ", value / 100);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.nodeRandomisation?.minZ) && touched.nodeRandomisation?.minZ}
              helperText={touched.nodeRandomisation?.minZ && errors.nodeRandomisation?.minZ}
            />
          </Grid>
          <Grid item>
            <WidgetInputNumber
              name="maxZ"
              label="Maximum Z axis"
              suffix="%"
              value={calcAxisValuePercentage(values.nodeRandomisation.maxZ)}
              disabled={isNodeRandomisationGlobal}
              onApply={(key, value) => {
                setFieldTouched("nodeRandomisation.maxZ", true);
                setFieldValue("nodeRandomisation.maxZ", value / 100);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.nodeRandomisation?.maxZ) && touched.nodeRandomisation?.maxZ}
              helperText={touched.nodeRandomisation?.maxZ && errors.nodeRandomisation?.maxZ}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} alignItems="center">
          <Grid item>
            <WidgetInputNumber
              name="randomSeed"
              label="Random Seed"
              value={values.nodeRandomisation.randomSeed}
              onApply={(key, value) => {
                setFieldTouched("nodeRandomisation.randomSeed", true);
                setFieldValue("nodeRandomisation.randomSeed", value);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.nodeRandomisation?.randomSeed) && touched.nodeRandomisation?.randomSeed}
              helperText={touched.nodeRandomisation?.randomSeed && errors.nodeRandomisation?.randomSeed}
            />
          </Grid>
          <Grid item>
            <WidgetHelperCardWithInfoPopover title="Random Seed">
              <Grid item>
                Set a random seed for the random function. Setting a random seed produces deterministic random results.
              </Grid>
            </WidgetHelperCardWithInfoPopover>
          </Grid>
        </Grid>
      </Grid>
    </WidgetSection>
  );
};

function WidgetFormPorous({ unitCells, scanningParameters, initialData, onSubmit, onUnitCellUpload }) {
  const defaultParameters = {
    name: "",
    family: "",
    scanningParameterId: "",
    sliceHeight: null,
    unitCell: "",
    nodeRandomisation: {
      minX: 0.3,
      maxX: 0.3,
      minY: 0.3,
      maxY: 0.3,
      minZ: 0.3,
      maxZ: 0.3,
      randomSeed: 0,
    },
    scaffoldRotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    roughening: {
      minExtension: 0,
      maxExtension: 0,
      distribution: [1],
      randomSeed: 0,
    },
  };

  const initialValuesEventual = Object.keys(initialData).length === 0 ? defaultParameters : initialData;

  Object.keys(defaultParameters).forEach((key) => {
    if (!initialValuesEventual[key]) {
      initialValuesEventual[key] = defaultParameters[key];
    }
  });

  return (
    <Formik initialValues={initialValuesEventual} validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
        <Form data-syklone="post-processing-porus">
          <Grid container item justifyContent="center" spacing={2} xs={12}>
            <Grid container item spacing={2} direction="column">
              <Grid item>
                <WidgetSection title="Name and Family" showDivider={false}>
                  <Grid item container spacing={3}>
                    <Grid item>
                      <TextField
                        name="name"
                        label="Name"
                        variant="outlined"
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="family"
                        label="Family"
                        variant="outlined"
                        value={values.family}
                        onChange={handleChange}
                        error={touched.family && Boolean(errors.family)}
                        helperText={touched.family && errors.family}
                      />
                    </Grid>
                  </Grid>
                </WidgetSection>
              </Grid>
              <Grid item>
                <WidgetSection title="Scanning Parameters">
                  <Grid item>
                    <Autocomplete
                      id="select-scanning-parameter"
                      name="scanningParameterId"
                      options={scanningParameters}
                      value={scanningParameters.find(
                        (parameter) => parameter._id.$oid === values.scanningParameterId?.$oid
                      )}
                      getOptionLabel={(option) => option?.buildName}
                      style={{ width: 280 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select scanning parameter"
                          variant="outlined"
                          error={touched.scanningParameterId && Boolean(errors.scanningParameterId)}
                          helperText={touched.scanningParameterId && errors.scanningParameterId}
                        />
                      )}
                      onChange={(e, value) => {
                        setFieldValue("scanningParameterId", value ? value._id : "");
                      }}
                    />
                  </Grid>
                </WidgetSection>
              </Grid>
              <Grid item>
                <SliceHeightSection
                  sliceHeight={values.sliceHeight}
                  error={errors.sliceHeight}
                  touched={touched.sliceHeight}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item>
                <UnitCellsSection unitCells={unitCells} onUnitCellUpload={onUnitCellUpload} />
              </Grid>
              <Grid item>
                <NodeRandomisationSection />
              </Grid>
              <Grid item>
                <WidgetSection
                  title="Scaffold Rotation Randomisation"
                  description="Define rotation of the whole porous structure from the origin in each axis. Rotation must be expressed in degrees in a range of [-180°, 180°]."
                >
                  <Grid container item spacing={2}>
                    <Grid item>
                      <WidgetInputNumber
                        name="x"
                        label="X axis"
                        suffix="°"
                        value={values.scaffoldRotation.x}
                        onApply={(key, value) => {
                          setFieldTouched("scaffoldRotation.x", true);
                          setFieldValue("scaffoldRotation.x", value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.scaffoldRotation?.x) && touched.scaffoldRotation?.x}
                        helperText={touched.scaffoldRotation?.x && errors.scaffoldRotation?.x}
                      />
                    </Grid>
                    <Grid item>
                      <WidgetInputNumber
                        name="y"
                        label="Y axis"
                        suffix="°"
                        value={values.scaffoldRotation.y}
                        onApply={(key, value) => {
                          setFieldTouched("scaffoldRotation.y", true);
                          setFieldValue("scaffoldRotation.y", value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.scaffoldRotation?.y) && touched.scaffoldRotation?.y}
                        helperText={touched.scaffoldRotation?.y && errors.scaffoldRotation?.y}
                      />
                    </Grid>
                    <Grid item>
                      <WidgetInputNumber
                        name="z"
                        label="Z axis"
                        suffix="°"
                        value={values.scaffoldRotation.z}
                        onApply={(key, value) => {
                          setFieldTouched("scaffoldRotation.z", true);
                          setFieldValue("scaffoldRotation.z", value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.scaffoldRotation?.z) && touched.scaffoldRotation?.z}
                        helperText={touched.scaffoldRotation?.z && errors.scaffoldRotation?.z}
                      />
                    </Grid>
                  </Grid>
                </WidgetSection>
              </Grid>
              <Grid item>
                <RougheningSection />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3} justifyContent="center">
              <Grid item>
                <WidgetJsonPreview json={values} />
              </Grid>
              <Grid item>
                <Button size="large" color="primary" variant="contained" type="submit">
                  Commit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

WidgetFormPorous.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onUnitCellUpload: PropTypes.func,
  scanningParameters: PropTypes.array,
  unitCells: PropTypes.array,
};

WidgetFormPorous.defaultProps = {
  initialData: {},
  onSubmit: () => {},
  onUnitCellUpload: () => {},
  scanningParameters: [],
  unitCells: [],
};

export default WidgetFormPorous;
