import React, { useState } from "react";
import { PropTypes } from "prop-types";

// imports: syklone
import {
  Autocomplete,
  Button,
  FieldArray,
  Form,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Formik,
  Grid,
  IconButton,
  icons,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useFormikContext,
  Yup,
} from "syklone/ui/index.js";
import { WidgetInfoBox, WidgetInputNumber } from "syklone/components/widgets/index.js";

// imports: local
import { WidgetHelperCard } from "../../../widgets/index.js";
import { withInfoPopover } from "../../../hoc/index.js";
import WidgetJsonPreview from "./widget_json_preview.jsx";
import WidgetSection from "./widget_section.jsx";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  family: Yup.string().required("Family is required"),
  skin: Yup.boolean().required(),
  sliceHeight: Yup.number().positive("Slice height must be a positive number.").required("Slice height is required."),
  hatch: Yup.boolean().required(),
  hatchType: Yup.string().when("hatch", { is: true, then: (schema) => schema.required("Hatch type is required.") }),
  hatchData: Yup.object().when("hatchType", {
    is: (value) => value === "rays",
    then: (schema) =>
      schema.required("Hatch data is required.").shape({
        hatchZoneBorder: Yup.number()
          .positive("Hatch zone border must be a positive number.")
          .required("Hatch zone border is required."),
        hatchDistance: Yup.number()
          .positive("Hatch distance must be a positive number.")
          .required("Hatch distance is required."),
        meanderFactor: Yup.number()
          .required("Meander factor is required.")
          .min(0, "Meander factor must be 0 or greater."),
        scanningParameterId: Yup.object().required("Scanning parameter is required"),
        hatchAngles: Yup.object().shape({
          legacyRotational: Yup.boolean().required(),
          initialAngle: Yup.number().when("legacyRotational", {
            is: (value) => value === true,
            then: () =>
              Yup.number()
                .min(0, "Initial angle must be 0 or greater.")
                .max(360, "Initial angle must be 360 or lower.")
                .required("Initial angle is required."),
            otherwise: () => Yup.number(),
          }),
          turningAngle: Yup.number().when("legacyRotational", {
            is: true,
            then: () =>
              Yup.number()
                .min(0, "Turning angle must be 0 or greater.")
                .max(360, "Turning angle must be 360 or lower.")
                .required("Turning angle is required."),
            otherwise: () => Yup.number(),
          }),
          alternatingAngles: Yup.array()
            .of(
              Yup.number()
                .min(0, "Alternating angle must be 0 or greater.")
                .max(360, "Alternating angle must be 360 or lower.")
                .required("Alternating angle is required.")
                .when("legacyRotational", {
                  is: true,
                  then: () => Yup.number().required("Alternating angle is required.").strip(),
                })
            )
            .nullable(true),
        }),
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  offsetBorders: Yup.array().of(
    Yup.object().shape({
      offset: Yup.number().required("Offset border is required."),
      scanningParameterId: Yup.object().required("Scanning parameter is required"),
    })
  ),
});

const WidgetHelperCardWithInfoPopover = withInfoPopover(WidgetHelperCard);

const SliceHeightSection = ({ setFieldTouched, setFieldValue, handleBlur, sliceHeight, error, touched }) => {
  const [sliceHeightUnit, setSliceHeightUnit] = useState("mm");

  return (
    <WidgetSection title="Slice Height">
      <Grid container item spacing={1} alignItems="center">
        <Grid item sx={{ width: "200px" }}>
          <WidgetInputNumber
            name="sliceHeight"
            label="Slice Height"
            suffix={sliceHeightUnit}
            value={sliceHeightUnit === "μm" ? sliceHeight * 1000 : sliceHeight}
            onApply={(key, value) => {
              setFieldTouched(key, true);
              let output = value;
              if (sliceHeightUnit === "μm") output = output / 1000;
              setFieldValue(key, output);
            }}
            onBlur={handleBlur}
            error={Boolean(error) && touched}
            helperText={touched && error}
          />
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={sliceHeightUnit}
            exclusive
            onChange={(e) => {
              setSliceHeightUnit(e.target.value);
            }}
            aria-label="text alignment"
          >
            <ToggleButton sx={{ textTransform: "none" }} value="mm" aria-label="milimeter">
              mm
            </ToggleButton>
            <ToggleButton sx={{ textTransform: "none" }} value="μm" aria-label="micrometer">
              μm
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </WidgetSection>
  );
};

SliceHeightSection.propTypes = {
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  sliceHeight: PropTypes.number,
  error: PropTypes.bool,
  touched: PropTypes.bool,
};

const HatchesSection = ({ scanningParameters }) => {
  const { values, errors, touched, handleBlur, setValues, setFieldValue, setFieldTouched } = useFormikContext();
  const onToggleHatch = (e, values, setValues) => {
    const newHatch = !values.hatch;

    if (newHatch) {
      setValues({
        ...values,
        hatch: newHatch,
        hatchType: "",
      });
    } else {
      const { hatchType, hatchData, ...rest } = values; // eslint-disable-line
      setValues({
        ...rest,
        hatch: newHatch,
      });
    }
  };

  const onToggleRotationalAngle = (e, values, setValues) => {
    const value = e.target.checked;
    if (value) {
      setValues({
        ...values,
        hatchData: {
          ...values.hatchData,
          hatchAngles: {
            legacyRotational: value,
            initialAngle: values.hatchData.initialAngle,
            turningAngle: values.hatchData.turningAngle,
          },
        },
      });
    } else {
      setValues({
        ...values,
        hatchData: {
          ...values.hatchData,
          hatchAngles: {
            legacyRotational: value,
            alternatingAngles: [],
          },
        },
      });
    }
  };

  const onChooseHatch = (e, values, setValues) => {
    const hatchType = e.target.value;
    const { offsetBorders } = values;

    let hatchData = {};

    switch (hatchType) {
      case "rays":
        hatchData = {
          scanningParameterId: "",
          hatchZoneBorder: undefined,
          hatchDistance: undefined,
          meanderFactor: undefined,
          hatchAngles: {
            legacyRotational: true,
          },
        };
        break;
      default:
    }

    setValues({
      ...values,
      offsetBorders,
      hatchType,
      hatchData,
    });
  };
  return (
    <WidgetSection
      title="Hatch"
      description="Hatch vectors denote the paths followed by the print head or extruder to deposit material and form
      successive layers, crucial for determining the objects strength and surface finish."
      adorment={
        <FormControlLabel
          sx={{ marginRight: 0, marginLeft: 0 }}
          control={
            <Switch
              checked={values.hatch}
              onChange={(e) => onToggleHatch(e, values, setValues)}
              name="hatch"
              color="primary"
            />
          }
        />
      }
    >
      {values.hatch && (
        <Grid container item alignItems="center" spacing={1}>
          <Grid item>
            <FormControl
              variant="outlined"
              sx={{ minWidth: 120 }}
              error={touched.hatchType && Boolean(errors.hatchType)}
            >
              <InputLabel id="select-hatch-type" key="select-hatch-type">
                Hatch Type
              </InputLabel>
              <Select
                name="hatchType"
                label="Hatch Type"
                style={{ minWidth: 120 }}
                onChange={(e) => onChooseHatch(e, values, setValues)}
                value={values.hatchType}
              >
                <MenuItem value="rays">Rays</MenuItem>
                <MenuItem value="longSegments">Long Segments</MenuItem>
              </Select>
              {errors.hatchType && touched.hatchType && <FormHelperText>{errors.hatchType}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item>
            <WidgetHelperCardWithInfoPopover title="Hatch Types">
              <Grid container>
                <Grid item>Rays - default, rays are infinite and defined by 2 values (equation)</Grid>
                <Grid item>
                  Long segments - (previously known as long rays): not implemented yet, rays are finite and defined by 2
                  points.
                </Grid>
              </Grid>
            </WidgetHelperCardWithInfoPopover>
          </Grid>
        </Grid>
      )}
      {(() => {
        switch (values.hatchType) {
          case "rays":
            return (
              <Grid item container spacing={2} mt={0.25} flexDirection="column">
                <Grid item container spacing={2} flexDirection="row">
                  <Grid container item spacing={1} alignItems="center" flexShrink={1} width="auto">
                    <Grid item>
                      <WidgetInputNumber
                        name="hatchData.hatchZoneBorder"
                        label="Hatch Zone Border"
                        suffix="mm"
                        value={values.hatchData.hatchZoneBorder}
                        onApply={(key, value) => {
                          setFieldTouched(key, true);
                          setFieldValue(key, value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.hatchData?.hatchZoneBorder) && touched.hatchData?.hatchZoneBorder}
                        helperText={touched.hatchData?.hatchZoneBorder && errors.hatchData?.hatchZoneBorder}
                      />
                    </Grid>
                    <Grid item>
                      <WidgetHelperCardWithInfoPopover title="Hatch Zone Border">
                        <Grid container>
                          Represents the (non-printed) border of the area to be hatched. Because hatches normally go
                          inwards, a positive value will offset inwards, while a negative value will offset outwards.
                          The default value is 0.
                        </Grid>
                      </WidgetHelperCardWithInfoPopover>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} alignItems="center" flexShrink={1} width="auto">
                    <Grid item>
                      <WidgetInputNumber
                        name="hatchData.hatchDistance"
                        label="Hatch Distance"
                        suffix="mm"
                        value={values.hatchData.hatchDistance}
                        onApply={(key, value) => {
                          setFieldTouched(key, true);
                          setFieldValue(key, value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.hatchData?.hatchDistance) && touched.hatchData?.hatchDistance}
                        helperText={touched.hatchData?.hatchDistance && errors.hatchData?.hatchDistance}
                      />
                    </Grid>
                    <Grid item>
                      <WidgetHelperCardWithInfoPopover title="Hatch Distance">
                        <Grid container>The separation between two consecutive hatch lines.</Grid>
                      </WidgetHelperCardWithInfoPopover>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} alignItems="center" flexShrink={1} width="auto">
                    <Grid item>
                      <WidgetInputNumber
                        name="hatchData.meanderFactor"
                        label="Meander Factor"
                        suffix="mm"
                        value={values.hatchData.meanderFactor}
                        onApply={(key, value) => {
                          setFieldTouched(key, true);
                          setFieldValue(key, value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.hatchData?.meanderFactor) && touched.hatchData?.meanderFactor}
                        helperText={touched.hatchData?.meanderFactor && errors.hatchData?.meanderFactor}
                      />
                    </Grid>
                    <Grid item>
                      <WidgetHelperCardWithInfoPopover title="Meander Factor">
                        <Grid container>
                          Represents the minimum distance required to meander join two hatch lines in the meander, if
                          the meander were to exceed that distance, the meander is not drawn. The default value is 0,
                          which means no meanders will be drawn.{" "}
                        </Grid>
                      </WidgetHelperCardWithInfoPopover>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                  <Grid container item alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="h6" color="inherit" noWrap>
                        Scanning Parameters
                      </Typography>
                    </Grid>
                    <Grid item>
                      <WidgetHelperCardWithInfoPopover title="Scanning Parameters">
                        <Grid container>
                          The scanning parameter that you wish the printer to apply to the defined hatch.
                        </Grid>
                      </WidgetHelperCardWithInfoPopover>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      id="select-scanning-parameter"
                      name="hatchData.scanningParameterId"
                      options={scanningParameters}
                      getOptionLabel={(option) => (option?.buildName ? option.buildName : "")}
                      value={scanningParameters.find((p) => p._id.$oid === values.hatchData?.scanningParameterId?.$oid)}
                      style={{ width: 280 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select scanning parameter"
                          variant="outlined"
                          error={
                            touched.hatchData?.scanningParameterId && Boolean(errors.hatchData?.scanningParameterId)
                          }
                          helperText={touched.hatchData?.scanningParameterId && errors.hatchData?.scanningParameterId}
                        />
                      )}
                      onChange={(e, value) => {
                        setFieldTouched("hatchData.scanningParameterId", true);
                        setFieldValue("hatchData.scanningParameterId", value ? value._id : "");
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="h6" color="inherit" noWrap>
                      Hatch angles
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.hatchData.hatchAngles.legacyRotational}
                          onChange={(e) => {
                            onToggleRotationalAngle(e, values, setValues);
                          }}
                          name="hatchData.hatchAngles.legacyRotational"
                          color="primary"
                        />
                      }
                      label="Legacy Rotation angles"
                    />
                  </Grid>
                  {values.hatchData.hatchAngles.legacyRotational ? (
                    <>
                      <Grid item container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="h6" color="inherit" noWrap>
                            Legacy rotational angles
                          </Typography>
                        </Grid>
                        <Grid item>
                          <WidgetHelperCardWithInfoPopover title="Legacy rotational angles">
                            <Grid item>Starting angle and rotation angle.</Grid>
                          </WidgetHelperCardWithInfoPopover>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item>
                            <WidgetInputNumber
                              name="hatchData.hatchAngles.initialAngle"
                              label="Initial Angle"
                              variant="outlined"
                              suffix="degrees"
                              value={values.hatchData.hatchAngles.initialAngle}
                              error={
                                touched.hatchData?.hatchAngles?.initialAngle &&
                                Boolean(errors.hatchData?.hatchAngles?.initialAngle)
                              }
                              helperText={
                                touched.hatchData?.hatchAngles?.initialAngle &&
                                errors.hatchData?.hatchAngles?.initialAngle
                              }
                              onApply={(key, value) => {
                                setFieldTouched(key, true);
                                setFieldValue(key, value);
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item>
                            <WidgetInputNumber
                              name="hatchData.hatchAngles.turningAngle"
                              label="Turning Angle"
                              variant="outlined"
                              suffix="degrees"
                              value={values.hatchData.hatchAngles?.turningAngle}
                              error={
                                touched.hatchData?.hatchAngles?.turningAngle &&
                                Boolean(errors.hatchData?.hatchAngles?.turningAngle)
                              }
                              helperText={
                                touched.hatchData?.hatchAngles?.turningAngle &&
                                errors.hatchData?.hatchAngles?.turningAngle
                              }
                              onApply={(key, value) => {
                                setFieldTouched(key, true);
                                setFieldValue(key, value);
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <FieldArray name="hatchData.hatchAngles.alternatingAngles">
                      {({ insert, remove, push }) => (
                        <>
                          <Grid container item alignItems="center" justifyContent="flex-start" spacing={1}>
                            <Grid item ml={1}>
                              <Typography variant="h6" color="inherit" noWrap>
                                Alternating angles
                              </Typography>
                            </Grid>
                            <Grid item>
                              <WidgetHelperCardWithInfoPopover title="Alternating angles">
                                <Grid item>Define any number of angles that will be selected in order.</Grid>
                              </WidgetHelperCardWithInfoPopover>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="success"
                                startIcon={<icons.mui.Add />}
                                onClick={() => push(0)}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                          {values?.hatchData?.hatchAngles?.alternatingAngles.length ? (
                            <Grid item container direction="column">
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Number</TableCell>
                                      <TableCell>Angle</TableCell>
                                      <TableCell>Delete</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values?.hatchData?.hatchAngles?.alternatingAngles?.map((angle, index) => (
                                      <TableRow key={`alternatingAngle${index}`}>
                                        <TableCell>
                                          <InputLabel>{index + 1}.</InputLabel>
                                        </TableCell>
                                        <TableCell>
                                          <WidgetInputNumber
                                            suffix="degrees"
                                            name={`hatchData.hatchAngles.alternatingAngles.${index}`}
                                            value={angle}
                                            onApply={(key, value) => {
                                              setFieldTouched(key, true);
                                              setFieldValue(key, value);
                                            }}
                                            onBlur={handleBlur}
                                            error={
                                              Boolean(errors.hatchData?.hatchAngles?.alternatingAngles?.[index]) &&
                                              touched.hatchData?.hatchAngles?.alternatingAngles?.[index]
                                            }
                                            helperText={
                                              touched.hatchData?.hatchAngles?.alternatingAngles?.[index] &&
                                              errors.hatchData?.hatchAngles?.alternatingAngles?.[index]
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <IconButton aria-label="delete" color="error" onClick={() => remove(index)}>
                                            <icons.mui.Delete />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          ) : (
                            <Grid item>
                              <WidgetInfoBox>No alternating angles defined.</WidgetInfoBox>
                            </Grid>
                          )}
                        </>
                      )}
                    </FieldArray>
                  )}
                </Grid>
              </Grid>
            );
          case "longSegments":
            return <Grid mt={1}>Not implemented yet.</Grid>;
          default:
        }
      })()}
    </WidgetSection>
  );
};

HatchesSection.propTypes = {
  scanningParameters: PropTypes.array,
};

const WidgetFormSolid = ({ scanningParameters, initialData, onSubmit }) => {
  const defaultParameters = {
    name: undefined,
    family: undefined,
    skin: false,
    sliceHeight: null,
    hatch: false,
  };

  const initialValuesEventual = Object.keys(initialData).length === 0 ? defaultParameters : initialData;

  Object.keys(defaultParameters).forEach((key) => {
    if (!initialValuesEventual[key]) {
      initialValuesEventual[key] = defaultParameters[key];
    }
  });

  return (
    <Formik initialValues={initialValuesEventual} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
      {({ values, touched, errors, setValues, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
        <Form data-syklone="post-processing-editor" style={{ width: "100%" }}>
          <Grid container item xs={12} flexDirection="column" spacing={2}>
            <WidgetSection title="Name and Family" showDivider={false}>
              <Grid container item spacing={3} alignItems="center">
                <Grid item>
                  <TextField
                    name="name"
                    label="Name"
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="family"
                    label="Family"
                    variant="outlined"
                    value={values.family}
                    onChange={handleChange}
                    error={touched.family && Boolean(errors.family)}
                    helperText={touched.family && errors.family}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.skin}
                        onChange={(e) => setFieldValue("skin", !values.skin)}
                        name="skin"
                        color="primary"
                      />
                    }
                    label="Skin"
                  />
                </Grid>
              </Grid>
            </WidgetSection>
            <Grid item>
              <SliceHeightSection
                sliceHeight={values.sliceHeight}
                error={errors.sliceHeight}
                touched={touched.sliceHeight}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </Grid>
            <HatchesSection scanningParameters={scanningParameters} />
            <Grid item container spacing={3} direction="column">
              <FieldArray name="offsetBorders">
                {({ remove, push }) => (
                  <WidgetSection
                    title="Offset Borders"
                    description={
                      <Grid container>
                        <Grid item>
                          Offset borders are printed contours. Any number of offset borders can be added.
                        </Grid>
                        <Grid item>WARNING: do not add more than 4 borders if you intend to label the component.</Grid>
                      </Grid>
                    }
                    adorment={
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<icons.mui.Add />}
                        onClick={() =>
                          push({
                            offset: 0,
                            scanningParameterId: "",
                            buildName: "",
                          })
                        }
                      >
                        Add
                      </Button>
                    }
                  >
                    {values.offsetBorders?.length ? (
                      <Grid item container direction="column">
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>
                                  <Grid container alignItems="center">
                                    Offset distance
                                    <WidgetHelperCardWithInfoPopover title="Offset distance">
                                      <Grid item>
                                        Define a border that offsets from the edge of the mesh (which is not printed). A
                                        positive value will offset outwards, while a negative value will do so inwards.
                                      </Grid>
                                    </WidgetHelperCardWithInfoPopover>
                                  </Grid>
                                </TableCell>
                                <TableCell>
                                  <Grid container alignItems="center">
                                    Scanning Parameter
                                    <WidgetHelperCardWithInfoPopover title="Offset distance">
                                      <Grid item>
                                        The scanning parameter that you wish the printer to apply to the defined offset
                                        border.
                                      </Grid>
                                    </WidgetHelperCardWithInfoPopover>
                                  </Grid>
                                </TableCell>
                                <TableCell>Delete</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.offsetBorders?.map((offsetBorder, index) => (
                                <TableRow key={`offsetBorders${index}`}>
                                  <TableCell>
                                    <InputLabel>{index + 1}.</InputLabel>
                                  </TableCell>
                                  <TableCell>
                                    <WidgetInputNumber
                                      suffix="mm"
                                      name={`offsetBorders.${index}.offset`}
                                      value={offsetBorder?.offset}
                                      onApply={(key, value) => {
                                        setFieldTouched(key, true);
                                        setFieldValue(key, value);
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        Boolean(errors.offsetBorders?.[index]?.offset) &&
                                        touched.offsetBorders?.[index]?.offset
                                      }
                                      helperText={
                                        touched.offsetBorders?.[index]?.offset && errors.offsetBorders?.[index]?.offset
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Autocomplete
                                      name={`offsetBorders.${index}.scanningParameterId`}
                                      value={offsetBorder?.buildName}
                                      options={scanningParameters}
                                      getOptionLabel={(option) => {
                                        /* Important to correctly display row data in case of deleting */
                                        if (typeof option === "string") {
                                          return option;
                                        }
                                        if (option.buildName) {
                                          return option.buildName;
                                        }
                                        return option.buildName;
                                      }}
                                      style={{ width: 280 }}
                                      includeInputInList
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select scanning parameter"
                                          variant="outlined"
                                          error={
                                            touched.offsetBorders?.[index]?.scanningParameterId &&
                                            Boolean(errors.offsetBorders?.[index]?.scanningParameterId)
                                          }
                                          helperText={
                                            touched.offsetBorders?.[index]?.scanningParameterId &&
                                            errors.offsetBorders?.[index]?.scanningParameterId
                                          }
                                        />
                                      )}
                                      onChange={(e, value) => {
                                        setFieldValue(
                                          `offsetBorders.${index}.scanningParameterId`,
                                          value ? value._id : ""
                                        );
                                        setFieldValue(`offsetBorders.${index}.buildName`, value ? value.buildName : "");
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton aria-label="delete" color="error" onClick={() => remove(index)}>
                                      <icons.mui.Delete />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : (
                      <Grid item>
                        <WidgetInfoBox>No offset borders found.</WidgetInfoBox>
                      </Grid>
                    )}
                  </WidgetSection>
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item>
                  <WidgetJsonPreview json={values} />
                </Grid>
                <Grid item>
                  <Button size="large" color="primary" variant="contained" type="submit">
                    Commit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

WidgetFormSolid.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  scanningParameters: PropTypes.array,
};

WidgetFormSolid.defaultProps = {
  initialData: {},
  onSubmit: () => {},
  scanningParameters: [],
};

export default WidgetFormSolid;
