/* 
* Automatically generated by cra-envs.
* If you wish to declare a new environment variable declare it in the .env file (prefixed by REACT_APP_)
* then run 'npx generate-env-getter js' at the root of your project.
* This file will be updated.
*/
import { getEnvVarValue } from "cra-envs";

export const envNames = [
    "ADMIN_FEATURES",
    "BUILD_ID",
    "BUILD_NUMBER",
    "DEBUG",
    "GIT_BRANCH",
    "GIT_COMMIT",
    "COOKIE_SESSION_DOMAIN",
    "URL_PAGE_BUILD_CREATOR",
    "URL_PAGE_GLOBAL_FRONTEND",
    "URL_PAGE_PART_CREATOR",
    "URL_PAGE_PLATFORM_CREATOR",
    "URL_SVC_BUILD_FILE_ORCHESTRATION",
    "URL_SVC_BUILD_CREATOR",
    "URL_SVC_PLATFORM_CREATOR",
    "URL_PRD_SVC_BUILD_FILE_MANAGER",
    "URL_PRD_COMPLIANCE_MODULE",
    "URL_PRD_SVC_AUTH_SIGN",
    "URL_PRD_SVC_BUILD_FILE_SCHEDULER",
    "URL_PRD_SVC_AUDIT_TRAIL"
];

let env = undefined;

export function getEnv() {

    if (env === undefined) {
        env = {};
        for (const envName of envNames) {
            env[envName] = getEnvVarValue(envName);
        }
    }

    return env;

}
