import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Autocomplete,
  Button,
  Form,
  Formik,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Yup,
} from "syklone/ui/index.js";
import { WidgetInputNumber } from "syklone/components/widgets/index.js";

// imports: local
import WidgetJsonPreview from "./widget_json_preview.jsx";
import WidgetSection from "./widget_section.jsx";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  family: Yup.string().required("Family is required"),
  scanningParameterId: Yup.object().required("Scanning parameter is required"),
  sliceHeight: Yup.number().positive("Slice height must be a positive number.").required("Slice height is required."),
});

const SliceHeightSection = ({ setFieldTouched, setFieldValue, handleBlur, sliceHeight, error, touched }) => {
  const [sliceHeightUnit, setSliceHeightUnit] = useState("mm");

  return (
    <WidgetSection title="Slice Height">
      <Grid container item spacing={1} alignItems="center">
        <Grid item sx={{ width: "200px" }}>
          <WidgetInputNumber
            name="sliceHeight"
            label="Slice Height"
            suffix={sliceHeightUnit}
            value={sliceHeightUnit === "μm" ? sliceHeight * 1000 : sliceHeight}
            onApply={(key, value) => {
              setFieldTouched(key, true);
              let output = value;
              if (sliceHeightUnit === "μm") output = output / 1000;
              setFieldValue(key, output);
            }}
            onBlur={handleBlur}
            error={Boolean(error) && touched}
            helperText={touched && error}
          />
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={sliceHeightUnit}
            exclusive
            onChange={(e) => {
              setSliceHeightUnit(e.target.value);
            }}
            aria-label="text alignment"
          >
            <ToggleButton sx={{ textTransform: "none" }} value="mm" aria-label="milimeter">
              mm
            </ToggleButton>
            <ToggleButton sx={{ textTransform: "none" }} value="μm" aria-label="micrometer">
              μm
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </WidgetSection>
  );
};

SliceHeightSection.propTypes = {
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  sliceHeight: PropTypes.number,
  error: PropTypes.bool,
  touched: PropTypes.bool,
};

function WidgetFormSupport({ scanningParameters, initialData, onSubmit }) {
  const defaultParameters = {
    name: "",
    family: "",
    scanningParameterId: "",
    sliceHeight: null,
  };

  const initialValuesEventual = Object.keys(initialData).length === 0 ? defaultParameters : initialData;

  Object.keys(defaultParameters).forEach((key) => {
    if (!initialValuesEventual[key]) {
      initialValuesEventual[key] = defaultParameters[key];
    }
  });

  return (
    <Formik initialValues={initialValuesEventual} validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
        <Form data-syklone="post-processing-porus">
          <Grid container item justifyContent="center" spacing={2} xs={12}>
            <Grid container item spacing={2} direction="column">
              <Grid item>
                <WidgetSection title="Name and Family" showDivider={false}>
                  <Grid item container spacing={3}>
                    <Grid item>
                      <TextField
                        name="name"
                        label="Name"
                        variant="outlined"
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="family"
                        label="Family"
                        variant="outlined"
                        value={values.family}
                        onChange={handleChange}
                        error={touched.family && Boolean(errors.family)}
                        helperText={touched.family && errors.family}
                      />
                    </Grid>
                  </Grid>
                </WidgetSection>
              </Grid>
              <Grid item>
                <WidgetSection title="Scanning Parameters">
                  <Grid item>
                    <Autocomplete
                      id="select-scanning-parameter"
                      name="scanningParameterId"
                      options={scanningParameters}
                      value={scanningParameters.find(
                        (parameter) => parameter._id.$oid === values.scanningParameterId?.$oid
                      )}
                      getOptionLabel={(option) => option?.buildName}
                      style={{ width: 280 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select scanning parameter"
                          variant="outlined"
                          error={touched.scanningParameterId && Boolean(errors.scanningParameterId)}
                          helperText={touched.scanningParameterId && errors.scanningParameterId}
                        />
                      )}
                      onChange={(e, value) => {
                        setFieldValue("scanningParameterId", value ? value._id : "");
                      }}
                    />
                  </Grid>
                </WidgetSection>
              </Grid>
              <Grid item>
                <SliceHeightSection
                  sliceHeight={values.sliceHeight}
                  error={errors.sliceHeight}
                  touched={touched.sliceHeight}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3} justifyContent="center">
              <Grid item>
                <WidgetJsonPreview json={values} />
              </Grid>
              <Grid item>
                <Button size="large" color="primary" variant="contained" type="submit">
                  Commit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

WidgetFormSupport.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  scanningParameters: PropTypes.array,
};

WidgetFormSupport.defaultProps = {
  initialData: {},
  onSubmit: () => {},
  scanningParameters: [],
};

export default WidgetFormSupport;
